/*
 * Silicon | Multipurpose Bootstrap 5 Template & UI Kit
 * Copyright 2023 Createx Studio
 * Theme styles
*/

// User variables
@import "user-variables";

// Configuration & utilities
@import "variables";
@import "variables-dark";
@import "utilities";

// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";

// Layout & components
@import "root";
@import "reboot";
@import "components";

// User custom styles
@import "user";

// external includes
@import '~boxicons/css/boxicons.min.css';
@import '~swiper/swiper-bundle.min.css';
@import '~lightgallery/css/lightgallery-bundle.min.css';


@font-face {
  font-family: 'Boxicons';
  src: url('~boxicons/fonts/boxicons.woff2') format('woff2'),
       url('~boxicons/fonts/boxicons.woff') format('woff'),
       url('~boxicons/fonts/boxicons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
